import React, { Suspense, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { viewerState } from "../db/viewerDb";
import WebFont from "webfontloader";
import log from "cslog";
import SinglePageView from "./views/singlepage/SinglePageView";
import { decodeAttribute } from "./junctionAbs";
// import DocumentViewer from "./DocumentViewer";

const Loading = () => {
	return <h1>Loading.....</h1>;
};

const CardView = React.lazy(() => import("./views/card/CardView"));
const CardstackView = React.lazy(() =>
	import("./views/cardstack/CardstackView")
);
const QDFView = React.lazy(() => import("./views/qdf/QDFView"));
// const StoriesView = React.lazy(() => import("./views/storiesview/StoriesView"));
const SliderView = React.lazy(() => import("./views/slider_new/SliderView"));
// const SliderView = React.lazy(() => import("./views/slider/SliderView"));

const CubeView = React.lazy(() => import("./views/cube/CubeView"));
const BookView = React.lazy(() => import("./views/book/BookView"));
const CardsView = React.lazy(() => import("./views/cards/CardsView"));
const MagazineView = React.lazy(() => import("./views/magazine/MagazineView"));

// const WebsiteViewer = React.lazy(() => import("./WebsiteViewer"));

const Viewer = ({ data }) => {
	const viewer = useRecoilValue(viewerState);

	// const router = useRouter();

	// const query_params = router.query;
	// delete query_params["id"];

	const vi = viewer?.input || {};



	log.d(data, "data in Viewer");
	log.d(viewer, "Viewer in Viewer");

	useEffect(() => {
		log.d("loading fonts");
		if (data) {
			const themeFontSet = data?.gconfig?.gconfig?.fontSets;
			log.d(themeFontSet, "themeFontSets");
			if (themeFontSet) {
				const fonts = Object.values(themeFontSet);
				log.d(fonts, "Loading fonts");
				WebFont.load({
					google: {
						families: fonts,
					},
				});
			}
		}
	}, [data]);

	if (!data) {
		return <h1>Invalid ID</h1>;
	}

	const pageSettings = data.gconfig.gconfig.page || {};

	const gs = {
		config: {
			...data.gconfig.gconfig.colors,
			...data.gconfig.gconfig.fontSets,
			...pageSettings,
		},
		gstyles: data.gconfig.gstyles.gstyles,
	};

	const ctx = {
		// query_params,
		// bg: "000000"
		background: decodeAttribute(vi.background ? vi.background : {}, gs)
	};

	let view = null;
	switch (data.type) {
		// case "website":
		//     view = <WebsiteViewer data={data} />;
		//     break;
		case "singlepage":
			view = <SinglePageView data={data} gs={gs} ctx={ctx}
				viewer={viewer?.input} />;
			break;
		// case "document":
		// 	view = <DocumentViewer data={data} />;
		// 	break;
		case "slider":
		case "flipbook":
			view = (
				<SliderView
					data={data}
					gs={gs}
					ctx={ctx}
					viewer={viewer?.input}
				/>
			);
			break;
		case "cardstack":
			view = <CardstackView data={data} />;
			break;
		case "card":
			view = <CardView data={data} />;
			break;
		case "cube":
			view = <CubeView data={data} />;
			break;
		case "magazine":
			// case "flipbook":
			view = <MagazineView data={data} />;
			break;
		// case "packaging_box":
		//     view = <PackagingBoxView data={data} />;
		//     break;
		default:
			view = null;
	}

	return <Suspense fallback={Loading}>{view}</Suspense>;
};

export default Viewer;
