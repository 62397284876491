import { ELEMENTS as DEFAULT } from "../data/defaults/responsive/elements";
import log from "cslog";

log.d(DEFAULT, "Default Elements");

export function parseDecodeStyle(data, gs, cstate = "normal") {
    const ps = parseStyle(data, gs, cstate);

    return {
        fixStyle: decodeStyle(ps.fixStyle, gs),
        style: decodeStyle(ps.style, gs),
        styleTab: decodeStyle(ps.style, gs),
        styleMob: decodeStyle(ps.mobile, gs),
        input: ps.input,
        itablet: ps.itablet,
        imobile: ps.imobile,
        classes: ps.classes,
    };
}

export function parseStyle(data, gs, cstate = "normal") {
    let root = data.extends;
    let rootStyles = {};

    // log.success(`PR ${data.type}`);
    // log.d(root, "PR root");
    // log.d(data, "PR Data");

    if (root) {
        switch (root.type) {
            case "default":
                rootStyles = parseStyle(DEFAULT[root.name], gs, cstate);
                break;
            case "gstyle":
                rootStyles = parseStyle(gs.gstyles[root.name], gs, cstate);
                break;
            default:
                break;
        }
    }

    const group = data.group || rootStyles.group || "basic";
    // log.d(group, "PR Group");

    let states = [];

    if (group === "complex") {
        states = {
            ...(rootStyles?.["states"] || {}),
            ...(data?.["states"] || {}),
        };
    } else {
        states = [
            ...(rootStyles?.["states"] || []),
            ...(data?.["states"] || []),
        ];
    }

    const out = {
        style: {
            ...rootStyles.style,
            ...data.style,
            ...(cstate === "hover" ? data.hover : {}),
        },
        fixStyle: { ...rootStyles.fixStyle, ...data.fixStyle },
        hover: { ...rootStyles.hover, ...data.hover },
        tablet: { ...rootStyles.tablet, ...data.tablet },
        mobile: { ...rootStyles.mobile, ...data.mobile },
        // complexStyle: { ...rootStyles.complexStyle, ...data.complexStyle },
        input: { ...rootStyles.input, ...data.input },
        // input: input,
        itype: { ...rootStyles.itype, ...data.itype },
        itablet: { ...rootStyles.itablet, ...data.itablet },
        imobile: { ...rootStyles.imobile, ...data.imobile },
        classes: { ...rootStyles.classes, ...data.classes },
        dataFields: { ...rootStyles.dataFields, ...data.dataFields },
        styleGroup: decodeGroups(rootStyles.styleGroup, data.styleGroup),
        mobileGroup: decodeGroups(rootStyles.mobileGroup, data.mobileGroup),
        states: states,
    };

    if (Array.isArray(states) && states?.length > 0) {
        states.forEach((st) => {
            const key = `style__${st}`;
            out[key] = {
                ...(rootStyles[key] || {}),
                ...(data[key] || {}),
            };
            if (cstate !== "normal") {
                out["style"] = {
                    ...out["style"],
                    ...(data[`style__${cstate}`] || {}),
                };
            }
        });
    } else if (states && typeof states === "object") {
        Object.entries(states).forEach(([key, val]) => {
            if (val?.length > 0) {
                val.forEach((st) => {
                    const styleName = st === "normal" ? key : `${key}__${st}`;
                    out["styleGroup"][styleName] = {
                        ...(rootStyles["styleGroup"]?.[styleName] || {}),
                        ...(data["styleGroup"]?.[styleName] || {}),
                    };
                });
                if (cstate[key] !== "normal") {
                    const styleName = `${key}__${cstate[key]}`;
                    out["styleGroup"][key] = {
                        ...out["styleGroup"][key],
                        ...out["styleGroup"][styleName],
                    };
                }
            }
        });
    }

    // log.d(out, "PR OUT");

    return out;
}

export function parseStyle2(data, gs, cstate = "normal") {
    let root = data.extends;
    let rootStyles = {};

    if (root) {
        switch (root.type) {
            case "default":
                rootStyles = parseStyle(
                    DEFAULT[root.name],
                    gs,
                    (cstate = cstate)
                );
                break;
            case "gstyle":
                rootStyles = parseStyle(
                    gs.gstyles[root.name],
                    gs,
                    (cstate = cstate)
                );
                break;
            default:
                break;
        }
    }

    // let input = {};
    // if (data["inputFirst"]) {
    //     input = { ...data.inputFirst, ...rootStyles.input, ...data.input };
    // } else {
    //     input = { ...rootStyles.input, ...data.input };
    // }

    let states = [];

    if (data.group === "complex" || rootStyles.group === "complex") {
        states = {
            ...(rootStyles?.["states"] || {}),
            ...(data?.["states"] || {}),
        };
    } else {
        states = [
            ...(rootStyles?.["states"] || []),
            ...(data?.["states"] || []),
        ];
    }

    const out = {
        style: {
            ...rootStyles.style,
            ...data.style,
            ...(cstate === "hover" ? data.hover : {}),
        },
        fixStyle: { ...rootStyles.fixStyle, ...data.fixStyle },
        hover: { ...rootStyles.hover, ...data.hover },
        tablet: { ...rootStyles.tablet, ...data.tablet },
        mobile: { ...rootStyles.mobile, ...data.mobile },
        // complexStyle: { ...rootStyles.complexStyle, ...data.complexStyle },
        input: { ...rootStyles.input, ...data.input },
        // input: input,
        itype: { ...rootStyles.itype, ...data.itype },
        itablet: { ...rootStyles.itablet, ...data.itablet },
        imobile: { ...rootStyles.imobile, ...data.imobile },
        classes: { ...rootStyles.classes, ...data.classes },
        dataFields: { ...rootStyles.dataFields, ...data.dataFields },
        styleGroup: decodeGroups(rootStyles.styleGroup, data.styleGroup),
        mobileGroup: decodeGroups(rootStyles.mobileGroup, data.mobileGroup),
        states: states,
    };

    if (Array.isArray(states) && states?.length > 0) {
        states.forEach((st) => {
            const key = `style__${st}`;
            out[key] = {
                ...(rootStyles[key] || {}),
                ...(data[key] || {}),
            };
            if (cstate !== "normal") {
                out["style"] = {
                    ...out["style"],
                    // ...(rootStyles[`style__${cstate}`] || {}),
                    ...(data[`style__${cstate}`] || {}),
                };
            }
        });
    } else if (states && typeof states === "object") {
        Object.entries(states).forEach(([key, val]) => {
            // log.d(val, key);
            if (val?.length > 0) {
                val.forEach((st) => {
                    const styleName = `${key}__${st}`;
                    // log.d(styleName, "sn in pr");
                    out["styleGroup"][styleName] = { hello: "world" };
                });
            }
        });
    }

    return out;
}

function decodeGroups(source, desti) {
    if (!desti) {
        return source || {};
    }
    if (!source) {
        return desti;
    }
    const out = { ...source };
    Object.keys(desti).forEach((key) => {
        out[key] = {
            ...source[key],
            ...desti[key],
        };
    });
    // log.d(out, "Out complex");
    return out;
}

export function decodeStyle(style, gs) {
    const result = {};
    let value = null;
    Object.keys(style).map((key) => {
        value = style[key];
        if (!value) {
            return "-";
        }
        if (typeof value === "object") {
            switch (value.type) {
                case "config":
                    result[key] = gs.config[value.value];
                    // log.p("GStyleConfig");
                    // log.d(gs.config[value.value], key);
                    break;
                // case "none":
                // 	break;
                default:
                    if (value.params?.type === "config") {
                        result[key] = decodeAttribute(value.params, gs);
                    } else {
                        result[key] = value.value;
                    }
            }
        } else {
            result[key] = value;
        }
    });
    return result;
}

export function decodeAttribute(value, gs) {
    switch (value.type) {
        case "config":
            return gs.config[value.value];
        default:
            return value.value;
    }
}
