export const THEME_COLORS = {
  // background color 1
  primaryColor: 'white',
  // background color 2
  primaryColor2: 'lightpink',
  // all main elements color
  secondryColor: 'royalblue',
  // attension seeking
  accentColor: 'cyan',
  // almost all text
  neutralColor: 'black',
  // all text less focused
  neutralColor2: 'grey'
}

export const THEME_FONTSET = {
  primaryFont: 'Lato',
  secondryFont: 'Raleway'
}

export const GSTYLE = {
  header: {
    extends: {
      type: 'default',
      name: 'header'
    },
    css: null,
    style: {},
    mobile: {},
    input: {}
  },
  p: {
    extends: {
      type: 'default',
      name: 'p'
    },
    css: null,
    style: {
      boxSizing: 'border-box',
      letterSpacing: 'normal'
    },
    mobile: {},
    input: {}
  },
  text: {
    extends: {
      type: 'default',
      name: 'text'
    },
    style: {},
    input: {},
    mobile: {}
  },
  button: {
    extends: {
      type: 'default',
      name: 'button'
    },
    css: null,
    style: {},
    hover: {},
    mobile: {},
    input: {}
  }
}
