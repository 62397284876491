import log from "cslog";
import { extractData } from "../../data/extractor";
import { bookInfoState, bookPageArrayState } from "../../db/bookConfig";
import { dataPageState } from "../../db/dataDb";
import { elementState } from "../../db/elementDb";

export const encodePageData = async (snapshot, page, book_id = null) => {
    const telementArray = {};
    // const cid = "root";
    const cid = page.pid;
    const data = await snapshot.getPromise(elementState(page.pid));

    await extractData(cid, data.pid, data, snapshot, telementArray);
    return {
        pid: page.pid,
        id: page.id,
        page_no: page.page_no,
        info: page,
        size: page.size,
        html: telementArray,
        content: await snapshot.getPromise(dataPageState(page.pid)),
        book_id: book_id,
    };
};

export const encodeMagazineData = async (snapshot, config) => {
    const info = await snapshot.getPromise(bookInfoState);

    const pages = [];
    const pageslist = await snapshot.getPromise(bookPageArrayState);
    log.d(pageslist, "BookPageLIST");

    const temp_pages = {};
    await Promise.all(
        pageslist.map(async (page) => {
            // log.d(page, "Next Page");
            const pageData = await encodePageData(snapshot, page, info.id);
            // log.d(page, "pPage");
            // pages.push(pageData);
            temp_pages[page.pid] = pageData;
        })
    );

    pageslist.forEach((page) => {
        pages.push(temp_pages[page.pid]);
    });

    const data = {
        page_array: pageslist,
        pages: pages,
    };

    return data;
};
