import log from "cslog";
import React, { useEffect } from "react";
import { useRecoilCallback, useRecoilState, useSetRecoilState } from "recoil";
import { useSingleItem } from "./actions/items";
import { GSTYLE } from "./data/gstyle";
import { editorModeState, editorTypeState } from "./db/editorConfig";
import { addGStyle } from "./db/gstyleDb";
import { siteLoaded } from "./db/siteConfig";
import { decodeData } from "./editors/decode";
import { restoreUserId } from "./_helper/auth";
import { useHistory } from "react-router-dom";

function initializeGStyle() {
    log.p("Initializing GlobalStyleEditor");

    Object.keys(GSTYLE).map((key) => {
        const style_data = { id: key, ...GSTYLE[key] };
        addGStyle(key, style_data);
        // log.d(style_data, key);
    });
}

function Decoder({ id }) {
    const history = useHistory();
    const { isLoading, isSuccess, data, isError } = useSingleItem(
        // "qp_magazine",
        "qp_creation",
        `/${id}?fields=*,permissions,creation_type.*,collaborators.access,collaborators.directus_users_id,organization.id,organization.name,organization.description,organization.logo`,
        id !== undefined
    );
    const setEtype = useSetRecoilState(editorTypeState);
    const [isSL, setSL] = useRecoilState(siteLoaded);

    const decode = useRecoilCallback(
        ({ snapshot, set }) =>
            async (creationData, my_access) => {
                log.h4("Decoding Data");
                log.d(creationData, "Incoming Data");

                initializeGStyle();

                await decodeData(creationData, set, snapshot);

                //Editor configs=====
                // content, design, full
                // await set(editorModeState, "full");
                if (my_access) {
                    await set(editorModeState, "full");
                    // await set(editorModeState, my_access);
                } else {
                    //access denied
                }
                //-------------------------

                setSL(true);
                set(siteLoaded, true);
                log.p("Data Decode Completed");
            }
    );

    useEffect(() => {
        if (isSL) {
            return;
        }
        if (isError) {
            history.push("/no_creation");
        }
        if (!id) {
            // send to choose creation page
            history.push("/no_creation");
        } else {
            if (data) {
                setEtype(data.info);

                const user_id = restoreUserId();

                const my_colab = data.collaborators?.filter(
                    (item) => item?.directus_users_id === user_id
                );

                // log.d(data, "Downloaded Data");
                decode(data, "full");
                // log.d(my_colab, "My colab");
                // if (data?.user_created === user_id || my_colab?.length > 0) {
                //     const my_access =
                //         my_colab?.length > 0 ? my_colab[0].access : "full";
                //     decode(data, my_access);
                // } else {
                //     history.push("/unauthorized");
                // }
            }
        }
    }, [id, data, isError]);

    return <></>;
}

export default Decoder;
