import log from "cslog";
import { VIEWERS } from "../data/extra/viewerData";
import {
	bookInfoState,
	bookLayoutState,
	creationTypeState,
	currentBookPageState,
	currentContextState,
	customDomainState,
	slugState,
	titleState,
	urlSettingsState
} from "../db/bookConfig";
import { globalVariablesConfigState, globalVariablesState } from "../db/dataDb";
import {
	globalConfigState,
	gstyleState,
	themeColorState,
	themeFontSetState,
	themePageState
} from "../db/gstyleDb";
import {
	DEFAULT_ROLE_LIST,
	enablePermissionsState,
	permissionsPageState,
	protectedAccessState,
	rolesState,
} from "../db/permissions";
import { seoBasicState } from "../db/seoDb";
import {
	extensionSettingsState,
	installedExtensionsState,
} from "../db/storeDb";
import { viewerState } from "../db/viewerDb";
import { decodeMagazineData } from "./magazine/decode";
import { decodeWebsiteData } from "./website/decode";

export const decodeData = async (data, set, snapshot) => {
	const info = data.info;
	// const etype = info.type;
	const etype = data.type;

	log.h4(`Decoding ${etype}`);

	// await set(organizationState, data.organization);
	await set(currentContextState, {
		organization: data.organization,
		person: data.person || { id: "d5f301f3-52a6-4025-a774-5397138cac36" },
	})

	await set(titleState, data.title);

	await set(slugState, data.slug);

	await set(customDomainState, data.custom_domain);

	await set(creationTypeState, data.creation_type);

	await set(urlSettingsState, data.urlsettings);

	await set(bookInfoState, info);

	await set(currentBookPageState, { pid: info.startPageId || "firstPage" });

	await set(bookLayoutState, data.layout);

	const gconfig = data.gconfig.gconfig;
	await set(themeColorState, gconfig.colors);
	await set(themeFontSetState, gconfig.fontSets);
	if (gconfig.page) {
		await set(themePageState, gconfig.page);
	}

	const gstyles = data.gconfig.gstyles;
	for (let key in gstyles) {
		// if (key === "config") {
		//     continue;
		// }
		await set(gstyleState(key), gstyles[key]);
	}

	const globalConfig = data.gconfig.globalConfig;
	await set(globalConfigState, globalConfig);

	const seo = data.seo;
	await set(seoBasicState, seo);

	// Global vars
	const globalVariables = data.others.globalVariables || {};
	if (
		!Object.keys(globalVariables).includes("organization") &&
		data.organization?.id
	) {
		globalVariables["organization"] = data.organization?.id;
	}
	await set(globalVariablesState, globalVariables);

	const globalVariablesConfig = data.others.globalVariablesConfig || {};
	if (
		!Object.keys(globalVariablesConfig).includes("organization") &&
		data.organization?.id
	) {
		globalVariablesConfig["organization"] = {
			name: "organization",
			vtype: "text",
			itype: "text",
			default_value: null,
		};
	}
	await set(globalVariablesConfigState, globalVariablesConfig);
	// -------------------------------

	const extensionSettings = data.others.extensionSettings || {};
	await set(extensionSettingsState, extensionSettings);

	const permissions_settings = data.permissions;
	await set(enablePermissionsState, !!permissions_settings.enable);
	await set(
		rolesState,
		permissions_settings.roles?.length > 0
			? permissions_settings.roles
			: DEFAULT_ROLE_LIST
	);
	await set(permissionsPageState, permissions_settings.page_permissions);

	await set(protectedAccessState, permissions_settings.protected_access || { enabled: false, password: "qureal" });

	// Always update viewer config params
	const viewer = data.viewers;
	const newViewer = VIEWERS[etype] || VIEWERS["default"];
	let updatedViewer = {
		input: {
			...newViewer.input,
			...viewer?.input,
		},
		itype: {
			...newViewer.itype,
			...viewer?.itype,
		},
	};
	await set(viewerState, updatedViewer);

	const extensions = data.extensions || {};
	await set(installedExtensionsState, (old_exts) => {
		const new_exts = {};
		Object.keys(extensions).forEach((key) => {
			if (!(key in old_exts)) {
				new_exts[key] = extensions[key];
			}
		});
		return {
			...old_exts,
			...new_exts,
		};
	});

	// const preinstalled_extensions= await snapshot.getPromise(installedExtensionsState)
	//========================================

	if (info.layout === "responsive" || info.layout === "grid" || info.layout === "document") {
		await decodeWebsiteData(data, set);
	} else if (info.layout === "absolute") {
		await decodeMagazineData(data, set);
	}
	// if (etype === "magazine") {
	//     await decodeMagazineData(data, set);
	// } else if (etype === "website") {
	//     await decodeWebsiteData(data, set);
	// } else if (etype === "ad") {
	//     await decodeAdData(data, set);
	// }
};
