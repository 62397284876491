import { atom, selector } from "recoil";
import { THEME_COLORS, THEME_FONTSET } from "../data/gstyle";

// export const elementArray = atom({
// 	key: "elementArray",
// 	default: {},
// });

export const gstyleArray = {};

// ===========================================

export const parsedGStyleArray = {};

export const gstyleArrayStateOLD = selector({
    key: "GStyleArray",
    get: ({ get }) => {
        // const result = { config: get(gstyleConfigState) };
        const result = { config: get(themeColorState) };
        Object.keys(gstyleArray).map((key) => {
            result[key] = get(gstyleState(key));
        });
        return result;
    },
});

// ===========================================

// export const themeArrayState = selector({
//     key: "themeArray",
//     get: ({ get }) => {
//         const result = {
//             config: get(gstyleConfigState),
//             colors: get(themeColorState),
//             fontset: get(themeFontSetState),
//         };
//         Object.keys(gstyleArray).map((key) => {
//             result[key] = get(gstyleState(key));
//         });
//         return result;
//     },
// });

export const themeArrayState = selector({
    key: "themeArray",
    get: ({ get }) => {
        const result = {
            // config: {
            //     ...get(themeColorState),
            //     ...get(themeFontSetState),
            // },
            config: get(themeConfigState),
        };
        const gstyles = {};
        Object.keys(gstyleArray).map((key) => {
            gstyles[key] = get(gstyleState(key));
        });
        result["gstyles"] = gstyles;
        return result;
    },
});

export const themeConfigState = selector({
    key: "themeConfig",
    get: ({ get }) => {
        return {
            ...get(themeColorState),
            ...get(themeFontSetState),
            ...get(themePageState),
        };
    },
});

// primary=60, secondary=30, accent=10
// 60 = background and all
// 30 = main elements and all
// 10 = somethign that really need attension like call to action buttons
// 60 and 30 should be somethign like (one bg, other fg)
// priamry and accent should be complementry (like blue and yellow)

// Primary= 60= have 2 or 3 primary colors

// Dominant, Complementary, Accent
export const themeColorState = atom({
    key: "themeColor",
    default: THEME_COLORS,
});

export const themeFontSetState = atom({
    key: "themeFontSet",
    default: THEME_FONTSET,
});

export const themeFontState = atom({
    key: "themeFont",
    default: {
        headingsFont: "Arial", // object of all font related properties
        paragraphsFonts: "Arial",
        buttons: "Arials",
        miscellaneous: "Aireal",
    },
});

export const themeExtraState = atom({
    key: "themeExtra",
    default: {
        pageBackground: "black",
        animation: "fade",
        animationSpeed: "medium",
    },
});

export const themePageState = atom({
    key: "themePage",
    default: {
        // pageMaxWidth: 1140,
        pageMaxWidth: 1080,
        pageMargin: {
            type: "vw",
            params: 4,
            value: "4vw",
        },
        pageBackground: {
            type: "config",
            value: "primaryColor",
        },
    },
});

//==================================================

export const gstyleConfigState = atom({
    key: "GStyleConfig",
    default: {},
});

export const globalConfigState = atom({
    key: "GlobalConfig",
    default: {
        defaultFontFamily: `Arial, Helvetica, sans-serif`,
        pageBackground: `#f9f6ff`,
    },
});

export const GLOBAL_CONFIG_TYPE = {
    defaultFontFamily: "fontFamily",
    pageBackground: "background",
};

// ===========================================

export const gstyleState = (id, data = null) => {
    if (id in gstyleArray) {
        return gstyleArray[id];
    }
    gstyleArray[id] = atom({
        key: id,
        default: data ? data : {},
    });
    return gstyleArray[id];
};

export const addGStyle = (id, initialData) => {
    if (id in gstyleArray) {
        return;
    }
    gstyleArray[id] = atom({
        key: id,
        default: initialData,
    });
};

export const selectedGStyle = atom({
    key: "selectedGStyle",
    default: null,
});

export const selectedThemeArea = atom({
    key: "selectedTheme",
    default: null,
});
