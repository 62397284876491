import React, { useEffect, useState } from "react";
import log from "cslog";
import { useItemsFine } from "../../actions/items";
import Handlebars from "handlebars/dist/cjs/handlebars";
import { Dropdown } from "semantic-ui-react";
import { useCreateItem } from "../../actions/mutations/items";

function RelationField({
	collection,
	junction_field,
	fieldString = "",
	template,
	value,
	onChange,
	many = false,
	org,
	fluid = false,
	allow_add = false,
	addition_field = "name",
	additional_options = [],
	loading = false
}) {
	const {
		data: dataItems,
		isLoading: loadingItems,
		isSuccess: successItems,
		isError: errorItems,
	} = useItemsFine(
		collection,
		fieldString,
		collection !== undefined,
		null,
		org
	);
	const [options, setOptions] = useState([]);
	const mutation_create = useCreateItem(collection, () => { }, (data) => {
		log.d("Item created");
		log.d(data, "Newly created item");
		if (junction_field) {
			setOptions(old_options => ([...old_options, { [junction_field]: data.id }]))
			onChange([...value, { [junction_field]: data.id }]);
		} else {
			setOptions(old_options => ([...old_options, data.id]))
			onChange([...value, data.id]);
		}
	});


	useEffect(() => {
		setOptions(old_options => {
			log.p("Updating option with addition ones")
			const new_options = additional_options.filter(item => !old_options.find(option => option.key === item.key))
			log.d(new_options, "New options")
			return [...old_options, ...(new_options.map(item => ({
				key: item,
				text: item,
				value: item
			})))];
		})
	}, [additional_options]);

	log.d(options, "Now options")


	useEffect(() => {
		if (dataItems) {
			log.p("refreshing items")
			const option_template = Handlebars.compile(template);

			const newOptions = [];

			dataItems.forEach((item) => {
				newOptions.push({
					key: item.id,
					text: option_template(item),
					value: junction_field ? {
						[junction_field]: item.id,
					} : item.id,
				});
			});

			setOptions(newOptions);
		}
	}, [dataItems]);

	const handleAddition = (e, { value }) => {
		log.d(value, "New item adding...");
		mutation_create.mutate({
			[addition_field]: value.toLowerCase(),
		})
	}

	log.d(value, "RelationField value");

	return (
		<Dropdown
			loading={loadingItems || loading}
			error={errorItems}
			value={value}
			onChange={(e, { value }) => onChange(value)}
			multiple={many}
			options={options}
			selection
			search
			fluid={fluid}
			allowAdditions={allow_add}
			onAddItem={handleAddition}
		/>
	);
}

export default RelationField;
