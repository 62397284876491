import { atom, selector, selectorFamily } from "recoil";
import log from "cslog";
import { elementState } from "./elementDb";
import { bookInfoState, currentBookPageState } from "./bookConfig";

export const EDITOR_VERSION = 2;

export const pageArray = {};

export const pageState = atom({
	key: "pages",
	default: [
		{ name: "Home", id: "home" },
		// { name: "404", id: "404" },
		// { name: "About Us", id: "about_us" },
	],
});

export const menuState = atom({
	key: "menuItems",
	default: [
		{
			name: "Home",
			id: "home",
			link: { type: "page", params: "home", value: "/home.html" },
		},
		{
			name: "About",
			id: "about",
			link: { type: "page", params: "about_us", value: "/about_us.html" },
		},
	],
});

export const selectedPageState = atom({
	key: "selectedPage",
	default: {
		id: "home",
		name: "Home",
	},
});

export const selectedBothPageIDState = selector({
	key: "selectedBothPageID",
	get: ({ get }) => {
		const info = get(bookInfoState);
		const current_page = get(selectedPageState);
		const current_book_page = get(currentBookPageState);
		const current_page_id =
			info?.layout === "responsive"
				? current_page?.id
				: current_book_page?.pid;
		return current_page_id;
	},
});

export const pageSectionsSelector = selectorFamily({
	key: "pageSections",
	get:
		(pageId) =>
			({ get }) => {
				const pageData = get(elementState(pageId));
				const sections = pageData.sections;
				const out = [];
				sections?.forEach((section_id) => {
					const section_data = get(elementState(section_id));
					out.push({
						id: section_id,
						name: section_data?.input?.name || "Unnamed",
					});
				});
				return out;
			},
});

export const siteLoaded = atom({
	key: "siteLoaded",
	default: false,
});

export const siteInfoState = atom({
	key: "siteInfoo",
	default: {
		id: null,
		siteplan: null,
		domain: null,
		custom_domain: null,
		editorInfo: {
			version: 1,
			editorVersion: EDITOR_VERSION,
			lastUpdated: new Date().getTime(),
		},
	},
});
