import React, { Fragment, useEffect, useState } from "react";
import { Form, Button, Icon, Popup, Divider, Segment } from "semantic-ui-react";
import log from "cslog";
import { iconOption, colorOption, selectOption } from "./SimpleOptions";
import { contentOptions } from "./ContentOptions";
import ImagePanel from "../widgets/ImagePanel";
import styles from "./styles/DataOptions.module.css";
import produce from "immer";
import DynamicDataOption from "../dynamic/DynamicDataOption";
import DynamicDataView from "../dynamic/DynamicDataView";
import { nanoid } from "nanoid";
import { ELEMENTS } from "../data/elements/responsive/elements";
import { addElement, elementState } from "../db/elementDb";
import { createIt } from "../editors/website/utils";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { dyanmicColumnOption, dynamicListOption } from "./DynamicOptions";
import { useSingleTable } from "../dynamic/apis/collections";
import { selectedPageState } from "../db/siteConfig";
import { dataPageState } from "../db/dataDb";

export function InternalPanel({
    data,
    setData,
    default_new_item,
    form_fields,
    display,
    child_default_new_item = null,
    child_form_fields = null,
    child_display = null,
    getDuplicateItem = null,
    onNewItemCreate = (newItem) => {},
    onItemDelete = (item) => {},
    onItemDuplicate = (source, desti) => {},
    enable_copy = true,
    enable_reorder = false,
}) {
    const [activeIndex, setActiveIndex] = useState(-1);
    const handleChange = (key, value) => {
        const newData = Array.from(data);
        newData[activeIndex] = {
            ...newData[activeIndex],
            [key]: value,
        };
        setData(newData);
    };

    const deleteItem = (index) => {
        const deleteItem = data[index];
        const newVal = Array.from(data);
        newVal.splice(index, 1);
        setData(newVal);
        onItemDelete(deleteItem);
    };

    const duplicateItem = (index) => {
        const newVal = Array.from(data);
        let item = {};
        if (getDuplicateItem) {
            item = getDuplicateItem(data[index]);
        } else {
            item = data[index];
        }
        newVal.splice(index, 0, item);
        setData(newVal);
        onItemDuplicate(data[index], item);
    };

    const addItem = (e) => {
        e.preventDefault();
        const newVal = Array.from(data);
        newVal.push(default_new_item);
        setData(newVal);
        onNewItemCreate(default_new_item);
    };

    const displayItem = (item) => {
        // display ? item[display] : "Edit"
        if (display) {
            if (display === "icon") {
                return <i className={item[display]?.value}></i>;
            }
            return item[display];
        }
        return "Edit";
    };

    const reorder = (startIndex, endIndex) => {
        if (endIndex < 0 || endIndex > data.length - 1) return;
        const result = Array.from(data);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        setData(result);
    };

    return (
        <Fragment>
            {data.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        <Form.Input placeholder="Item1">
                            <Button
                                basic
                                // color="grey"
                                icon
                                fluid
                                style={
                                    {
                                        // textAlign: "left",
                                    }
                                }
                                labelPosition="left"
                                onClick={() => {
                                    if (index === activeIndex) {
                                        setActiveIndex(-1);
                                    } else {
                                        setActiveIndex(index);
                                    }
                                }}
                            >
                                {/* <i className={item.icon.value}></i>
                        {"  "} {item.text.substring(0, 10)}... */}
                                <Icon name="edit" />
                                {/* {display ? item[display] : "Edit"} */}
                                <div
                                    style={{
                                        textAlign: "left",
                                        paddingLeft: "20px",
                                    }}
                                >
                                    {displayItem(item)}
                                </div>
                            </Button>
                            {enable_copy && (
                                <Button
                                    basic
                                    icon="copy"
                                    style={{
                                        marginLeft: "5px",
                                        // opacity: data.length <= 1 ? 0 : 1,
                                    }}
                                    onClick={() => duplicateItem(index)}
                                />
                            )}
                            {enable_reorder && (
                                <div className={styles.up_down_wrapper}>
                                    <div
                                        className={styles.up_down_btn}
                                        onClick={() => {
                                            reorder(index, index - 1);
                                        }}
                                    >
                                        {"⮝"}
                                    </div>
                                    <div
                                        className={styles.up_down_btn}
                                        onClick={() => {
                                            reorder(index, index + 1);
                                        }}
                                    >
                                        {"⮟"}
                                    </div>
                                </div>
                            )}
                            <Button
                                icon="delete"
                                basic
                                style={{
                                    marginLeft: "5px",
                                    // opacity: data.length <= 1 ? 0 : 1,
                                }}
                                onClick={() => deleteItem(index)}
                            />
                        </Form.Input>
                        {index === activeIndex && (
                            <Segment style={{ marginTop: "-10px" }}>
                                {form_fields.map((ff) => {
                                    if (ff.type === "self" && item[ff.field]) {
                                        return (
                                            <Form.Field key={ff.field}>
                                                <label>{ff.label}</label>
                                                <InternalPanel
                                                    data={item[ff.field]}
                                                    setData={(newVal) => {
                                                        handleChange(
                                                            ff.field,
                                                            newVal
                                                        );
                                                    }}
                                                    default_new_item={
                                                        child_default_new_item
                                                    }
                                                    display={child_display}
                                                    form_fields={
                                                        child_form_fields
                                                    }
                                                />
                                            </Form.Field>
                                        );
                                    }
                                    return contentOptions(
                                        ff.label,
                                        ff.type,
                                        item,
                                        item[ff.field],
                                        (kkey, vval) =>
                                            handleChange(ff.field, vval),
                                        {},
                                        () => {},
                                        {},
                                        {},
                                        {},
                                        ff.rest
                                    );
                                })}
                            </Segment>
                        )}
                    </React.Fragment>
                );
            })}

            <Button onClick={addItem} icon labelPosition="left">
                <Icon name="add" />
                Add Item
            </Button>
        </Fragment>
    );
}

export const TableDataStatic = ({ tableData, setTableData }) => {
    const columns = tableData["columns"];

    const setValue = (newVal) =>
        setTableData({
            ...tableData,
            columns: newVal,
        });

    const default_new_item = {
        key: `column_${columns.length + 1}`,
        name: `Column ${columns.length + 1}`,
        editor: "text",
    };

    return (
        <Form.Field key="static_table_options">
            <label>Columns</label>
            <InternalPanel
                data={columns}
                setData={setValue}
                default_new_item={default_new_item}
                form_fields={[
                    { label: "Key", field: "key", type: "shorttext" },
                    { label: "Name", field: "name", type: "shorttext" },
                    {
                        label: "Editor",
                        field: "editor",
                        type: "shorttext",
                    },
                ]}
                display="name"
                getDuplicateItem={(item) => {
                    return {
                        ...item,
                        id: nanoid(10),
                    };
                }}
            />
        </Form.Field>
    );
};

export const ColumnSelector = ({
    all_cols,
    selected_cols,
    setSelectedCols,
}) => {
    const [sel_col_list, setSelColList] = useState([]);

    useEffect(() => {
        const new_sel_col_list = selected_cols.map((sc) => sc.key);
        setSelColList(new_sel_col_list);
    }, [selected_cols]);

    log.d(sel_col_list, "Selected columns list");

    return (
        <Form.Field>
            <label>Column Selected</label>
            <div className={styles.list}>
                {all_cols.map((col) => {
                    return (
                        <div className={styles.list_item}>
                            <input
                                type="checkbox"
                                value={col.label}
                                id={`col_sel_${col.key}`}
                                onClick={(e) => {
                                    if (sel_col_list.includes(col.key)) {
                                        log.d(col, "Removing");
                                        const new_val = selected_cols.filter(
                                            (c) => c.key !== col.key
                                        );
                                        log.d(new_val, "New Val");
                                        setSelectedCols(new_val);
                                    } else {
                                        log.d(col, "Adding");
                                        setSelectedCols([
                                            ...selected_cols,
                                            col,
                                        ]);
                                    }
                                }}
                                checked={sel_col_list.includes(col.key)}
                            />
                            <label for={`col_sel_${col.key}`}>{col.name}</label>
                        </div>
                    );
                })}
            </div>
        </Form.Field>
    );
};

export const TableDataDynamic = ({ tableData, setTableData }) => {
    const currentPage = useRecoilValue(selectedPageState);
    const db = useRecoilValue(dataPageState(currentPage?.id));
    const { data: table, isSuccess: successTable } = useSingleTable(
        db[tableData?.source]?.data?.table
    );

    const fields = successTable
        ? table?.fields?.map((f) => ({
              key: f.name,
              name: f.label,
              editor: "text",
          }))
        : [];

    log.d(tableData, "tableData in TDD");
    log.d(db, "DB in TDD");
    log.d(table, "Table in TDD");
    log.d(fields, "Fields in TDD");

    useEffect(() => {
        if (fields?.length > 0) {
            setTableData({
                ...tableData,
                columns: fields,
            });
        }
    }, [tableData?.source]);

    return (
        <>
            {dynamicListOption(
                "Table Data Source",
                tableData.source,
                (newSource) =>
                    setTableData({
                        ...tableData,
                        source: newSource,
                    })
            )}
            {tableData?.columns && (
                <ColumnSelector
                    all_cols={fields}
                    selected_cols={tableData.columns}
                    setSelectedCols={(newCols) =>
                        setTableData({
                            ...tableData,
                            columns: newCols,
                        })
                    }
                />
            )}
        </>
    );
};

export const GraphDataOption = ({
    label,
    input,
    setOneInput,
    data,
    setData,
    gs,
    single = false,
}) => {
    const graphData = input[single ? "graphDataSingle" : "graphData"];

    const setGraphData = (newVal) =>
        setOneInput(single ? "graphDataSingle" : "graphData", newVal);

    const currentPage = useRecoilValue(selectedPageState);
    const db = useRecoilValue(dataPageState(currentPage?.id));
    const { data: table, isSuccess: successTable } = useSingleTable(
        db[graphData?.source]?.table
    );

    // const fields = successTable
    //     ? table?.fields?.map((f) => [f.label, f.name])
    //     : [];
    const fields = successTable ? table?.fields : [];

    log.d(single, "Single");
    log.d(graphData, "tableData in GDD");
    log.d(fields, "Feidls in GDD");

    return (
        <>
            {dynamicListOption("Data Source", graphData.source, (newSource) =>
                setGraphData({
                    ...graphData,
                    type: "dynamic",
                    source: newSource,
                    lines: [],
                })
            )}
            {fields?.length > 0 && (
                <>
                    {selectOption(
                        "Base Axis",
                        graphData.xAxis,
                        (newVal) =>
                            setGraphData({ ...graphData, xAxis: newVal }),
                        fields.map((f) => [f.label, f.name])
                    )}
                    {single ? (
                        <>
                            {dyanmicColumnOption(
                                "Value",
                                graphData.yAxis,
                                (newVal) =>
                                    setGraphData({
                                        ...graphData,
                                        yAxis: newVal,
                                    }),
                                {
                                    fields: fields.filter(
                                        (f) => f.value_type === "integer"
                                    ),
                                }
                            )}
                        </>
                    ) : (
                        <Form.Field key="Values">
                            <label>Values</label>
                            <InternalPanel
                                label="Lines"
                                data={graphData.lines || []}
                                setData={(newData) =>
                                    setGraphData({
                                        ...graphData,
                                        lines: newData,
                                    })
                                }
                                default_new_item={{
                                    column: "id",
                                }}
                                form_fields={[
                                    {
                                        label: "Column",
                                        field: "column",
                                        type: "dynamicColumn",
                                        rest: {
                                            fields: fields.filter(
                                                (f) =>
                                                    f.value_type === "integer"
                                            ),
                                        },
                                    },
                                ]}
                                display="column"
                                onNewItemCreate={(newItem) => {
                                    setGraphData({
                                        ...graphData,
                                        lines: [...graphData.lines, newItem],
                                    });
                                }}
                                onItemDelete={(item) => {
                                    const newChilds = data.childs.filter(
                                        (ch) => ch !== item.id
                                    );
                                    const newTabs = input.tabs.filter(
                                        (tab) => tab.id !== item.id
                                    );
                                    setData({
                                        ...data,
                                        input: {
                                            ...data.input,
                                            tabs: newTabs,
                                        },
                                        childs: newChilds,
                                    });
                                }}
                            />
                        </Form.Field>
                    )}
                </>
            )}
        </>
    );
};

export const TableDataOption = ({
    label,
    input,
    setOneInput,
    data,
    setData,
    gs,
}) => {
    const tableData = input["tableData"];

    const switchToDynamic = () => {
        if (tableData.type !== "dynamic") {
            setOneInput("tableData", {
                type: "dynamic",
            });
        }
    };

    const switchToStatic = () => {
        if (tableData.type === "dynamic") {
            setOneInput("tableData", {
                type: "static",
                columns: [
                    { key: "name", name: "Name", editor: "text" }, //editor: false for disable
                    { key: "title", name: "Title", editor: "text" },
                ],
                rows: [
                    { name: "Mohan", title: "Example" },
                    { name: "Rajesh", title: "Demo" },
                ],
            });
        }
    };

    return (
        <>
            <div className={styles.btn_group}>
                <Button
                    primary={tableData?.type === "static"}
                    onClick={switchToStatic}
                >
                    Static
                </Button>
                <Button
                    primary={tableData?.type === "dynamic"}
                    onClick={switchToDynamic}
                >
                    Dynamic
                </Button>
            </div>
            {tableData.type === "dynamic" ? (
                <TableDataDynamic
                    tableData={tableData}
                    setTableData={(newVal) => setOneInput("tableData", newVal)}
                />
            ) : (
                <TableDataStatic
                    tableData={tableData}
                    setTableData={(newVal) => setOneInput("tableData", newVal)}
                />
            )}
            {/* pagination */}
        </>
    );
};

export const TabsDataOption = ({
    label,
    input,
    setOneInput,
    data,
    setData,
    gs,
}) => {
    log.d(data, "TabsDataIn Option");
    const value = input["tabs"];

    const setValue = (newVal) => setOneInput("tabs", newVal);

    const default_new_item = {
        id: nanoid(10),
        name: `Tab ${data.childs?.length + 1}`,
        icon: {
            params: {
                type: "fa",
                name: "coffee",
            },
            value: "fa fa-coffee",
        },
    };

    const addChildContainer = (id) => {
        // add container

        const header_id = nanoid(10);
        const header_data = {
            ...ELEMENTS["header"],
            pid: id,
            input: {
                ...ELEMENTS["header"]["input"],
                content: `Tab ${data.childs.length + 1}`,
            },
        };

        const new_tab_data = {
            ...ELEMENTS["box"],
            id: id,
            pid: data.id,
            input: {
                ...ELEMENTS["box"]["input"],
                isAlone: true,
            },
            childs: [header_id],
        };
        addElement(header_id, header_data);
        addElement(id, new_tab_data);
    };

    const cloneTab = useRecoilCallback(
        ({ set, snapshot }) =>
            async (source_id, desti_id) => {
                const desti_data = await snapshot.getPromise(
                    elementState(source_id)
                );
                createIt(desti_id, data.id, desti_data, snapshot);
            }
    );

    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <InternalPanel
                data={value}
                setData={setValue}
                default_new_item={default_new_item}
                form_fields={[
                    { label: "Icon", field: "icon", type: "iconSrc" },
                    { label: "Name", field: "name", type: "shorttext" },
                ]}
                display="name"
                enable_copy={false}
                enable_reorder={true}
                getDuplicateItem={(item) => {
                    return {
                        ...item,
                        id: nanoid(10),
                    };
                }}
                onNewItemCreate={(newItem) => {
                    addChildContainer(newItem?.id);
                    setData({
                        ...data,
                        input: {
                            ...data.input,
                            tabs: [...data.input.tabs, newItem],
                        },
                        childs: [...data.childs, newItem.id],
                    });
                }}
                onItemDuplicate={(source, desti) => {
                    cloneTab(source.id, desti.id);
                }}
                onItemDelete={(item) => {
                    const newChilds = data.childs.filter(
                        (ch) => ch !== item.id
                    );
                    const newTabs = input.tabs.filter(
                        (tab) => tab.id !== item.id
                    );
                    setData({
                        ...data,
                        input: {
                            ...data.input,
                            tabs: newTabs,
                        },
                        childs: newChilds,
                    });
                }}
            />
        </Form.Field>
    );
};

export const iconListDataOption = (label, value, setValue) => {
    log.d(value, "iconListDataValues");

    const default_new_item = {
        text: "New Item",
        icon: {
            params: {
                type: "fa",
                name: "coffee",
            },
            value: "fa fa-coffee",
        },
        action: {
            type: "none",
        },
    };

    return (
        <Form.Field key={label}>
            <label>{label} ICD</label>
            <InternalPanel
                data={value}
                setData={setValue}
                default_new_item={default_new_item}
                form_fields={[
                    { label: "Icon", field: "icon", type: "iconSrc" },
                    { label: "Text", field: "text", type: "shorttext" },
                    { label: "Action", field: "action", type: "action" },
                ]}
            />
        </Form.Field>
    );
};

export const imgArrayOptions = (label, value, setValue) => {
    log.d(value, "iconListDataValues");

    const ImgGrid = ({ items }) => {
        return (
            <div className={styles.imgarrWrapper}>
                {items.map((item, index) => {
                    return (
                        <div className={styles.imgWrapper}>
                            <img alt="item" src={item.full} />
                            <div
                                className={styles.overlay}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setValue(
                                        produce(items, (draft) => {
                                            draft.splice(index, 1);
                                        })
                                    );
                                }}
                            >
                                <Icon name="times" size="large" />
                            </div>
                        </div>
                    );
                })}
                <div
                    style={{
                        height: "50px",
                        width: "100%",
                        backgroundColor: "#eee",
                        overflow: "hidden",
                        outline: "1px solid #ddd",
                        fontWeight: "bold",
                        fontSize: "3rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#ccc",
                    }}
                >
                    +
                </div>
            </div>
        );
    };

    return (
        <Form.Field key={label}>
            {/* <label>{label}</label> */}
            <label>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        cursor: "pointer",
                    }}
                >
                    <span>{label}</span>
                    <DynamicDataOption
                        value={value}
                        onChange={setValue}
                        ftype="imgArray"
                    />
                </div>
            </label>
            {Array.isArray(value) && (
                <ImagePanel
                    button={<ImgGrid items={value} />}
                    multiple={true}
                    minNumber={2}
                    onPhoto={(photos) => {
                        setValue(photos);
                    }}
                />
            )}
            {!Array.isArray(value) && (
                <DynamicDataView
                    value={value}
                    reset={() => {
                        setValue([]);
                    }}
                />
            )}
        </Form.Field>
    );
};

export const socialListDataOptions = (label, value, setValue) => {
    const default_new_item = {
        icon: {
            params: {
                type: "fab",
                name: "facebook",
            },
            value: "fab fa-facebook",
        },
        color: "blue",
        action: {
            type: "none",
        },
    };

    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <InternalPanel
                data={value}
                setData={setValue}
                default_new_item={default_new_item}
                form_fields={[
                    { label: "Icon", field: "icon", type: "iconSrc" },
                    { label: "Color", field: "color", type: "color" },
                    { label: "Action", field: "action", type: "action" },
                ]}
                display="icon"
            />
        </Form.Field>
    );
};

export const breadcrumListDataOptions = (label, value, setValue) => {
    const default_new_item = {
        label: "Item1",
        action: {
            type: "none",
        },
    };

    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <InternalPanel
                data={value}
                setData={setValue}
                default_new_item={default_new_item}
                form_fields={[
                    { label: "Label", field: "label", type: "shorttext" },
                    { label: "Action", field: "action", type: "action" },
                ]}
                display="label"
            />
        </Form.Field>
    );
};

export const navMenuDataOptions = (label, value, setValue) => {
    const default_new_item = {
        name: "NewItem",
        action: {
            type: "none",
        },
        children: [],
    };

    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <InternalPanel
                data={value}
                setData={setValue}
                default_new_item={default_new_item}
                display="name"
                enable_copy={false}
                enable_reorder={true}
                form_fields={[
                    { label: "Name", field: "name", type: "shorttext" },
                    { label: "Action", field: "action", type: "action" },
                    { label: "Children", field: "children", type: "self" },
                ]}
                child_default_new_item={{
                    name: "New Child",
                    action: {
                        type: "none",
                    },
                }}
                child_form_fields={[
                    {
                        label: "Name",
                        field: "name",
                        type: "shorttext",
                    },
                    {
                        label: "Action",
                        field: "action",
                        type: "action",
                    },
                ]}
                child_display="name"
            />
        </Form.Field>
    );
};
