const WIDGETS = {
    iconList: {
        id: "iconList",
        etype: "widgets",
        type: "iconList",
        group: "complex",
        pgroup: "pro",
        name: "Icon List",
        extends: {
            type: "default",
            name: "iconList",
        },
        includes: {
            text: "p",
            icon: "icon",
        },
        dims: {
            w: 120,
            h: 150,
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    countdown: {
        id: "countdown",
        etype: "widgets",
        type: "countdown",
        group: "basic",
        pgroup: "pro",
        name: "Countdown",
        extends: {
            type: "default",
            name: "countdown",
        },
        css: null,
        dims: {
            w: 300,
            h: 100,
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    codeview: {
        id: "codeview",
        etype: "widgets",
        type: "codeview",
        // group: "basic",
        group: "widgets",
        pgroup: "pro",
        name: "Code View",
        extends: {
            type: "default",
            name: "codeview",
        },
        css: null,
        dims: {
            w: 300,
            h: 150,
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    viewer3d: {
        id: "viewer3d",
        etype: "widgets",
        type: "viewer3d",
        group: "basic",
        pgroup: "pro",
        name: "3D Viewer",
        extends: {
            type: "default",
            name: "viewer3d",
        },
        css: null,
        dims: {
            w: 300,
            h: 100,
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    audioPlayer: {
        id: "audioPlayer",
        etype: "widgets",
        type: "audioPlayer",
        group: "basic",
        pgroup: "pro",
        name: "Audio Player",
        extends: {
            type: "default",
            name: "audioPlayer",
        },
        css: null,
        dims: {
            w: 300,
            h: 100,
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    qrCode: {
        id: "qrCode",
        etype: "widgets",
        type: "qrCode",
        group: "basic",
        pgroup: "free",
        name: "QR Code",
        extends: {
            type: "default",
            name: "qrCode",
        },
        css: null,
        dims: {
            w: 120,
            h: 120,
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    map: {
        id: "map",
        etype: "widgets",
        type: "map",
        group: "basic",
        pgroup: "free",
        name: "Map",
        extends: {
            type: "default",
            name: "map",
        },
        css: null,
        dims: {
            w: 120,
            h: 100,
        },
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    pageNumber: {
        id: "pageNumber",
        etype: "widgets",
        type: "pageNumber",
        group: "basics",
        pgroup: "free",
        name: "Page Number",
        extends: {
            type: "default",
            name: "pageNumber",
        },
        dims: {
            w: 120,
            h: 60,
        },
        cstate: "normal",
        css: null,
        style: {},
        mobile: {},
        input: {},
        itype: {},
    },
    invisibleButton: {
        id: "invisibleButton",
        etype: "widgets",
        type: "invisibleButton",
        group: "basics",
        pgroup: "free",
        name: "Invisible Button",
        extends: {
            type: "default",
            name: "invisibleButton",
        },
        dims: {
            w: 120,
            h: 60,
        },
        cstate: "normal",
        css: null,
        style: {},
        mobile: {},
        input: {},
        itype: {},
    },
};

export default WIDGETS;
