const PANEL_ABSOLUTE = [
	{
		id: 'primary',
		name: 'Basic Elements',
		items: [
			'header',
			'p',
			// "text",
			'htmltext',
			'button',
			'link',
			'img',
			'video',
			'icon',
			'divider',
			'graphic',
			// 'shape',
			'slidingText',
			'list',
			'richDialog'
		]
	},
	{
		id: 'widgets',
		name: 'Widget Elements',
		items: [
			'codeview',
			'qrCode',
			'viewer3d',
			'audioPlayer',
			'iconList',
			// 'countdown',
			// "map",
			'pageNumber',
			'invisibleButton'
		]
		// items: [],
	}
]

export default PANEL_ABSOLUTE
