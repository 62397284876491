import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Button, Dropdown, Form, Icon, Popup } from 'semantic-ui-react'
import { dataPageState, globalVariablesConfigState } from '../db/dataDb'
import { selectedBothPageIDState, selectedPageState } from '../db/siteConfig'
import log from 'cslog'
// import { FieldDropdown } from "./fields/db";
import FieldDropdown from './FieldDropdown'
import { } from './fields/db'
import { useSingleTable } from './apis/collections'
import { currentCtxState } from '../db/elementDb'
import { OnlyValueField, OperatorField, ValueField } from './fields/Filter'
import { startCase } from 'lodash'
import { additionalDataSourceState } from '../db/storeDb'
import { InternalPanel } from '../customize/DataOptions'
import { InternalListOption, selectOption } from '../customize/SimpleOptions'
import { bookInfoState, currentBookPageState } from '../db/bookConfig'

export function DataSourceDropdown({
	value,
	setValue,
	ctx = {},
	filterFunc = () => true
}) {
	const current_page_id = useRecoilValue(selectedBothPageIDState)

	// const currentPage = useRecoilValue(selectedPageState);
	const db = useRecoilValue(dataPageState(current_page_id))
	const additional_db = useRecoilValue(additionalDataSourceState)
	const [parent, setParent] = useState(null)

	// const parent = ctx?.list ? db[ctx.list] : null;
	useEffect(() => {
		setParent(ctx?.list ? db[ctx.list] : null)
	}, [ctx, db])

	const options = Object.keys(db)
		.filter(key => filterFunc(db[key]))
		.map(key => {
			const item = db[key]
			return {
				key: key,
				text: item.name,
				// value: item,
				value: key
			}
		})

	if (parent) {
		options.push({
			key: 'parent',
			text: `Parent ${parent ? '(' + parent.name + ')' : ''}`,
			value: 'parent'
		})
	}

	// options.push({
	// 	key: 'gv',
	// 	text: 'Global Variables',
	// 	value: 'gv'
	// })

	Object.values(additional_db).forEach(obj => {
		if (filterFunc(obj)) {
			options.push({
				key: obj.id,
				text: obj.name,
				value: obj.id
			})
		}
	})

	return (
		<Dropdown
			placeholder='Collection'
			selection
			options={options}
			onChange={(e, { value }) => setValue(value)}
			value={value}
		/>
	)
}

function DynamicDataOption({
	value,
	onChange,
	ftype,
	auto_configure = false,
	trigger = (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				gap: '5px'
			}}
		>
			Dynamic
			<Icon name='database' link />
		</div>
	),
	ExtraComponent = null,
	block_close = false
}) {
	const current_page_id = useRecoilValue(selectedBothPageIDState)
	// const currentPage = useRecoilValue(selectedPageState);
	const ctx = useRecoilValue(currentCtxState)
	const db = useRecoilValue(dataPageState(current_page_id))
	const additional_db = useRecoilValue(additionalDataSourceState)
	const currentDb = db[value?.id === 'parent' ? ctx?.list : value?.id]
	const { data: tableData } = useSingleTable(
		currentDb?.data?.table,
		currentDb?.block_type === 'qureal_database'
	)
	const gvc = useRecoilValue(globalVariablesConfigState)
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (value?.id === 'parent') {
			if (auto_configure && tableData) {
				log.p('Auto configuring dynamic data option')
				//check if table has id field
				const has_id = tableData.fields.findIndex(f => f.name === 'id') !== -1
				if (has_id) {
					onChange({
						id: 'parent',
						field: 'id'
					})
				}
			}
		}
	}, [ctx, tableData])

	useEffect(() => {
		// if (ctx?.list && auto_configure) {
		if (ctx?.list) {
			if (!value?.id) {
				onChange({
					id: 'parent'
				})
			}
		}
	}, [ctx])


	useEffect(() => {
		if (open && !block_close) {
			setOpen(false);
		}
	}, [block_close])


	const getTableData = value => {
		if (value?.id === 'gv') {
			return {
				fields: Object.values(gvc).map(ff => ({
					id: ff.name,
					name: ff.name,
					label: startCase(ff.name),
					value_type: ff.vtype,
					interface: {
						type: ff.itype
					}
				}))
			}
		}
		if (Object.keys(additional_db).includes(value?.id)) {
			const ds = additional_db[value?.id]
			return {
				fields: ds.fields.map(ff => ({
					id: ff.id,
					name: ff.id,
					label: ff.name,
					value_type: ff.vtype,
					interface: {
						type: ff.itype
					}
				}))
			}
		}
		return tableData
	}

	return (
		<Popup on='click' pinned trigger={<span onClick={() => setOpen(true)}>{trigger}</span>}
			closeOnDocumentClick={!block_close}
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
		>
			<Form>
				<Form.Field>
					<label>Data Source</label>
					<DataSourceDropdown
						ctx={ctx}
						value={value?.id}
						setValue={newVal =>
							onChange({
								id: newVal
							})
						}
					/>
				</Form.Field>
				<Form.Field>
					{currentDb?.block_type === 'fillable' &&
						selectOption(
							'',
							value?.field,
							newVal =>
								onChange({
									...value,
									field: newVal
								}),
							currentDb.data?.fields?.map(item => [
								item.display_name,
								item.name
							])
						)}
					{currentDb?.block_type === 'CSV' &&
						selectOption(
							'',
							value?.field,
							newVal =>
								onChange({
									...value,
									field: newVal
								}),
							currentDb.data?.fields?.map(item => [
								item.display_name,
								item.name
							])
						)}
					{/* {currentDb?.block_type !== "fillable" && ( */}
					{!['fillable', 'CSV'].includes(currentDb?.block_type) && (
						<FieldDropdown
							// collection={value?.collection?.collection}
							label='Field'
							table={getTableData(value)}
							// table={
							//     value?.id === "gv"
							//         ? {
							//               fields: Object.values(gvc).map((ff) => ({
							//                   id: ff.name,
							//                   name: ff.name,
							//                   label: startCase(ff.name),
							//                   value_type: ff.vtype,
							//                   interface: {
							//                       type: ff.itype,
							//                   },
							//               })),
							//           }
							//         : tableData
							// }
							ftype={ftype}
							value={value?.field}
							setValue={newVal => {
								onChange({
									...value,
									field: newVal
								})
							}}
						/>
					)}
				</Form.Field>
				{ExtraComponent && <ExtraComponent value={value} onChange={onChange} />}
			</Form>
		</Popup>
	)
}

export default DynamicDataOption

export const DynamicConditionCreateOption = ({
	trigger,
	onCreate,
	onUpdate,
	onClosePopup,
	initOpen = false,
	initData = null
}) => {
	const [open, setOpen] = useState(false)
	const [value, setValue] = useState({})
	// const currentPage = useRecoilValue(selectedPageState);
	const current_page_id = useRecoilValue(selectedBothPageIDState)
	const ctx = useRecoilValue(currentCtxState)
	const db = useRecoilValue(dataPageState(current_page_id))
	const currentDb = db[value?.id === 'parent' ? ctx?.list : value?.id]
	const { data: tableData } = useSingleTable(currentDb?.data?.table)
	const [selectedField, setSelectedField] = useState(null)
	const gvc = useRecoilValue(globalVariablesConfigState)

	useEffect(() => {
		if (initOpen) {
			setOpen(true)
			setValue(initData)
		} else {
			setValue({})
		}
	}, [initOpen, initData])

	useEffect(() => {
		if (ctx?.list) {
			if (!value?.id) {
				setValue({
					id: 'parent'
				})
			}
		}
	}, [ctx])

	const updated_table_data =
		value?.id === 'gv'
			? {
				fields: Object.values(gvc).map(ff => ({
					id: ff.name,
					name: ff.name,
					label: startCase(ff.name),
					value_type: ff.vtype,
					interface: {
						type: ff.itype
					}
				}))
			}
			: tableData

	return (
		<Popup
			on='click'
			pinned
			trigger={trigger}
			flowing
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => {
				setOpen(false)
				onClosePopup()
			}}
		>
			<Form>
				<Form.Field>
					<label>Data Source</label>
					<DataSourceDropdown
						ctx={ctx}
						value={value?.id}
						setValue={newVal =>
							setValue({
								id: newVal
							})
						}
					/>
				</Form.Field>
				<Form.Field>
					<label>Field</label>
					<FieldDropdown
						// collection={value?.collection?.collection}
						label='Field'
						table={updated_table_data}
						ftype='all'
						value={value?.field}
						setValue={newVal => {
							setValue({
								...value,
								field: newVal
							})
							setSelectedField(
								updated_table_data?.fields?.find(ff => ff.name === newVal)
							)
						}}
					/>
				</Form.Field>
				<OperatorField
					label='Operator'
					value={value?.op}
					setValue={newVal =>
						setValue({
							...value,
							op: newVal
						})
					}
					field={selectedField}
					filter={value}
					setFilter={setValue}
				/>
				<ValueField
					label={false}
					filter={value}
					field={selectedField}
					value={value.value}
					setValue={newVal => setValue({ ...value, value: newVal })}
					table={tableData}
				//  page={page}
				/>
				<Button
					onClick={() => {
						if (initData) {
							onUpdate(value)
						} else {
							onCreate(value)
						}
						setOpen(false)
						setValue({})
					}}
				>
					Save
				</Button>
			</Form>
		</Popup>
	)
}

export const DynamicFiltersConfigOption = ({ value, setValue }) => {
	const current_page_id = useRecoilValue(selectedBothPageIDState)
	// const currentPage = useRecoilValue(selectedPageState);
	const ctx = useRecoilValue(currentCtxState)
	const db = useRecoilValue(dataPageState(current_page_id))
	const currentDb = db[value?.source === 'parent' ? ctx?.list : value?.source]
	const { data: tableData } = useSingleTable(currentDb?.data?.table)
	const [selectedField, setSelectedField] = useState(null)
	const gvc = useRecoilValue(globalVariablesConfigState)

	const updated_table_data =
		value?.source === 'gv'
			? {
				fields: Object.values(gvc).map(ff => ({
					id: ff.name,
					name: ff.name,
					label: startCase(ff.name),
					value_type: ff.vtype,
					interface: {
						type: ff.itype
					}
				}))
			}
			: tableData

	log.d(value, 'Value in Dynamic Filters Options')

	const setField = new_val => {
		const sel_field = updated_table_data?.fields?.find(
			ff => ff.name === new_val
		)
		log.d(sel_field, 'Selected Fielddddd')
		setValue({
			...value,
			field: {
				name: sel_field.name,
				label: sel_field.label,
				interface: sel_field.interface,
				value_type: sel_field.value_type,
				field_type: sel_field.field_type
			}
		})
		setSelectedField(sel_field)
	}

	return (
		<div>
			<Form.Field>
				<label>Applicable Data Source</label>
				<DataSourceDropdown
					ctx={ctx}
					value={value?.source}
					setValue={newVal =>
						setValue({
							source: newVal
						})
					}
				/>
			</Form.Field>
			{value?.source && (
				<Form.Field>
					<label>Field</label>
					<FieldDropdown
						// collection={value?.collection?.collection}
						label='Field'
						table={updated_table_data}
						ftype='all'
						value={value?.field?.name}
						setValue={newVal => {
							setField(newVal)
						}}
					/>
				</Form.Field>
			)}
			{value.source && value.field?.name && (
				<Form.Field>
					<label>Options Type</label>
					<Dropdown
						options={[
							{ key: 'static', text: 'Static', value: 'static' },
							{
								key: 'dynamic',
								text: 'Dynamic',
								value: 'dynamic'
							}
						]}
						selection
						value={value.option_type}
						onChange={(e, { value: newVal }) =>
							setValue({
								...value,
								option_type: newVal
							})
						}
					/>
				</Form.Field>
			)}
			{value.source && value.field?.name && (
				<Form.Checkbox
					label='Allow multiple'
					checked={value.allow_multiple}
					onChange={() =>
						setValue({
							...value,
							allow_multiple: !value.allow_multiple
						})
					}
				/>
			)}
			{value.option_type === 'static' && (
				<StaticOptionsListOption
					options={Array.isArray(value.options) ? value.options : []}
					setOptions={newOptions => setValue({ ...value, options: newOptions })}
					field={value.field}
				/>
			)}
		</div>
	)
}

function StaticOptionsListOption({ field, options, setOptions }) {
	// const [options, setOptions] = useState([]);

	log.d(field, 'Field in SOLO')

	return (
		<InternalListOption
			label='Static Options List'
			value={options}
			setValue={setOptions}
			new_item={{ value: null, label: '' }}
			item_render={(item, handleChange, index) => {
				return (
					<OnlyValueField
						field={field}
						value={item?.value}
						setValue={newVal =>
							// handleChange(index, {
							//     value: newVal,
							//     label: newVal,
							// })
							handleChange(index, newVal)
						}
					/>
				)
			}}
		/>
	)
}
