import WIDGETS_COMMON from "../common/widgets";
import { combineElements } from "../../../parser/allparser";

const ABSOLUTE = {
    iconList: {},
    navMenu: {},
    countdown: {},
    codeview: {},
    viewer3d: {},
    audioPlayer: {},
    qrCode: {},
    map: {},
    invisibleButton: {},
};

const WIDGETS_ABSOLUTE = combineElements(WIDGETS_COMMON, ABSOLUTE);
export default WIDGETS_ABSOLUTE;
