import log from "cslog";
import {
	bookInfoState,
	bookLayoutState,
	currentContextState,
	customDomainState,
	titleState,
	urlSettingsState
} from "../db/bookConfig";
import { globalVariablesConfigState, globalVariablesState } from "../db/dataDb";
import {
	globalConfigState,
	// gstyleArrayState,
	themeArrayState,
	themeColorState,
	themeFontSetState,
	themePageState,
} from "../db/gstyleDb";
import { permissionsPageState, protectedAccessState, rolesState } from "../db/permissions";
import { seoBasicState } from "../db/seoDb";
import {
	extensionSettingsState,
	installedExtensionsState,
} from "../db/storeDb";
import { viewerState } from "../db/viewerDb";
import { encodeMagazineData } from "./magazine/encode";
import { encodeWebsiteData, encodeWebsiteDataMinimal } from "./website/encode";

export const encodeData = async (snapshot, config, minimal = false) => {
	log.h4("Encoding Website Data");

	const out = {};

	// out["organization"] = await snapshot.getPromise(organizationState);
	const current_context = await snapshot.getPromise(currentContextState);
	out["organization"] = current_context?.organization;
	// out["person"] = current_context?.person;

	out["title"] = await snapshot.getPromise(titleState);
	// out["slug"] = "demo_slug";

	out["custom_domain"] = await snapshot.getPromise(customDomainState);

	const info = await snapshot.getPromise(bookInfoState);
	out["info"] = info;

	out["layout"] = await snapshot.getPromise(bookLayoutState);

	out["gconfig"] = {
		gconfig: {
			colors: await snapshot.getPromise(themeColorState),
			fontSets: await snapshot.getPromise(themeFontSetState),
			page: await snapshot.getPromise(themePageState),
		},
		gstyles: await snapshot.getPromise(themeArrayState),
		globalConfig: await snapshot.getPromise(globalConfigState),
	};

	if (info.layout === "responsive" || info.layout === "grid" || info.layout === "document") {
		if (minimal) {
			out["data"] = await encodeWebsiteDataMinimal(snapshot, null);
		} else {
			out["data"] = await encodeWebsiteData(snapshot, null);
		}
	} else if (info.layout === "absolute") {
		out["data"] = await encodeMagazineData(snapshot, null);
	}

	out["others"] = {
		globalVariables: await snapshot.getPromise(globalVariablesState),
		globalVariablesConfig: await snapshot.getPromise(
			globalVariablesConfigState
		),
		extensionSettings: await snapshot.getPromise(extensionSettingsState),
	};

	out["seo"] = await snapshot.getPromise(seoBasicState);

	out["viewers"] = await snapshot.getPromise(viewerState);

	out["extensions"] = await snapshot.getPromise(installedExtensionsState);

	out["urlsettings"] = await snapshot.getPromise(urlSettingsState);

	out["permissions"] = {
		enable: false,
		roles: await snapshot.getPromise(rolesState),
		page_permissions: await snapshot.getPromise(permissionsPageState),
		protected_access: await snapshot.getPromise(protectedAccessState)
	};

	log.d(out, "Encoded DATA");
	return out;
};
